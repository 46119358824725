<template>
  <div>
    <title-bar :title-stack="titleStack"></title-bar>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          <span class="icon"><i class="mdi mdi-ballot default"></i></span>
          Listar Perfis
        </p>
        <router-link
          slot="right"
          :to="`/formPerfil`"
          class="button"
          style="margin-right: 1.5rem; margin-top: 1rem; margin-bottom: 1rem"
        >
          Adicionar
        </router-link>
      </header>

      <div class="card-content">
        <form @submit.prevent="buscar()">
          <div class="columns is-multiline is-variable is-1-mobile">
            <!-- Perfil -->
            <div class="column is-full-mobile is-half-desktop">
              <div class="field">
                <div class="control">
                  <label class="label">Perfil</label>
                  <b-input
                    v-model="filtro.perfil"
                    icon="card-bulleted-outline"
                    placeholder="Digite um perfil"
                    name="perfil"
                    class="is-fullwidth"
                  ></b-input>
                </div>
              </div>
            </div>

            <!-- Descrição -->
            <div class="column is-full-mobile is-half-desktop">
              <div class="field">
                <div class="control">
                  <label class="label">Descrição</label>
                  <b-input
                    v-model="filtro.descricao"
                    icon="card-bulleted-outline"
                    placeholder="Digite uma descrição"
                    name="descricao"
                    class="is-fullwidth"
                  ></b-input>
                </div>
              </div>
            </div>

            <!-- Botão Buscar -->
            <div class="column is-full-mobile is-flex is-align-items-end">
              <div class="field">
                <div class="control">
                  <b-button
                    native-type="submit"
                    type="is-info"
                    class="is-fullwidth"
                  >
                    Buscar
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <section class="section is-main-section">
        <CardComponent class="has-table has-mobile-sort-spaced">
          <b-table striped hoverable :data="resultList">
            <b-table-column label="Id" field="id" v-slot="item">
              {{ item.row.id }}
            </b-table-column>

            <b-table-column label="Role" field="role" v-slot="item">
              {{ item.row.role }}
            </b-table-column>

            <b-table-column label="Descrição" field="descricao" v-slot="item">
              {{ item.row.descricao }}
            </b-table-column>

            <b-table-column
              v-slot="item"
              label="Ações"
              custom-key="actions"
              centered
              cell-class="is-actions-cell"
            >
              <div class="buttons no-wrap is-centered">
                <b-tooltip label="Agendamento">
                  <router-link
                    :to="{
                      name: 'FormPerfil.edit',
                      params: { id: item.row.id },
                    }"
                    class="button is-small is-info"
                  >
                    <b-icon icon="eye" size="is-small" />
                  </router-link>
                </b-tooltip>
              </div>
            </b-table-column>
          </b-table>
        </CardComponent>
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import CardComponent from "@/components/CardComponent.vue";
import TitleBar from "@/components/TitleBar.vue";
import RoleService from "@/services/RoleService";

export default defineComponent({
  name: "ListPerfis",
  components: {
    TitleBar,
    CardComponent,
  },
  data() {
    return {
      titleStack: ["SECAPI", "Configurações", "Gerenciar Perfis"],
      filtro: {},
      pageable: {
        current: 1,
        size: 0,
      },
      totalElements: null,
      resultList: [],
    };
  },
  methods: {
    buscar() {
      if (this.filtro) {
        this.pageable.current = 1;
        let queryFiltro = `gerenciarPerfis?page=${
          this.pageable.current
        }${this.$util.formatQueryUrl(this.filtro)}&orderBy=id,DESC`;
        this.$router.push(queryFiltro).catch(() => {});
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.query) {
          this.pageable.current = parseInt(newVal.query.page);

          RoleService.getRoles(
            this.pageable.current - 1,
            this.$util.formatQueryUrl(this.$route.query)
          ).then((result) => {
            this.resultList = result.data.content;
            this.pageable.current = result.data.number + 1;
            this.pageable.size = result.data.totalElements;
          });

          this.filtro = {};
        }
      },
    },
  },
});
</script>
