<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          <span class="icon"><i class="mdi mdi-ballot default"></i></span>
          {{ getTitle() }}
        </p>
        <router-link
          slot="right"
          :to="`/adicionarPessoa?tipoPessoa=${$route.query.tipoPessoa}`"
          class="button is-info"
          style="margin-right: 1.5rem; margin-top: 1rem; margin-bottom: 1rem"
        >
          Adicionar
        </router-link>
      </header>

      <div class="card-content">
        <form @submit.prevent="buscar()">
          <!-- Container responsivo -->
          <div class="columns is-multiline is-variable is-1-mobile">
            <!-- Nome -->
            <div class="column is-full-mobile is-one-quarter-desktop">
              <div class="field">
                <div class="control">
                  <label class="label">Nome</label>
                  <b-input
                    v-model="filtro.nome"
                    icon="card-bulleted-outline"
                    placeholder="Digite o Nome"
                    name="nome"
                    class="is-fullwidth"
                  />
                </div>
              </div>
            </div>
            <!-- Empresa -->
            <div class="column is-full-mobile is-one-quarter-desktop">
              <div class="field">
                <div class="control">
                  <label class="label">Empresa</label>
                  <b-input
                    v-model="filtro.empresa"
                    icon="card-bulleted-outline"
                    placeholder="Digite a Empresa"
                    name="empresa"
                    class="is-fullwidth"
                  />
                </div>
              </div>
            </div>

            <!-- CPF -->
            <div class="column is-full-mobile is-one-quarter-desktop">
              <div class="field">
                <div class="control">
                  <label class="label">CPF</label>
                  <b-input
                    v-model="filtro.cpf"
                    icon="card-bulleted-outline"
                    placeholder="Digite o CPF"
                    name="cpf"
                    class="is-fullwidth"
                  />
                </div>
              </div>
            </div>

            <!-- Status -->
            <div class="column is-full-mobile is-one-quarter-desktop">
              <div class="field">
                <div class="control">
                  <label class="label">Status</label>
                  <b-select
                    v-model="filtro.status"
                    placeholder="Status"
                    icon="card-bulleted-outline"
                    class="is-fullwidth"
                  >
                    <option
                      v-for="(item, index) in status"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </option>
                  </b-select>
                </div>
              </div>
            </div>

            <!-- Botão Buscar -->
            <div
              class="column is-full-mobile is-one-quarter-desktop is-flex is-align-items-end"
            >
              <div class="field">
                <div class="control">
                  <b-button
                    native-type="submit"
                    type="is-info"
                    class="is-fullwidth"
                  >
                    Buscar
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <section class="section is-main-section">
        <card-component class="has-table has-mobile-sort-spaced">
          <modal-delete-pessoa
            :is-active="isModalActive"
            :trash-object-name="trashObject ? trashObject.nome : null"
            @confirm="trashConfirm"
            @cancel="trashCancel"
          />

          <modal-desligamento
            :is-active="isModalDesligarPessoaActive"
            :pessoa="desligarPessoaObject"
            @confirm="desligarPessoaConfirm"
            @cancel="desligarPessoaCancel"
          />

          <b-table
            :checked-rows.sync="checkedRows"
            :data="pessoasTable"
            default-sort="name"
            striped
            hoverable
          >
            <b-table-column v-slot="pessoa" label="Nome" field="nome" sortable>
              {{ pessoa.row.nome }}
            </b-table-column>

            <b-table-column v-slot="pessoa" label="CPF" field="cpf" sortable>
              {{ pessoa.row.cpf | VMask("###.###.###-##") }}
            </b-table-column>

            <b-table-column
              v-slot="pessoa"
              label="Empresa"
              field="empresa"
              sortable
            >
              {{ pessoa.row.empresa }}
            </b-table-column>

            <b-table-column
              v-slot="pessoa"
              label="Status"
              field="status"
              centered
              sortable
            >
              <span class="tag" :class="estiloStatus(pessoa.row.status)">
                {{ pessoa.row.status }}
              </span>
            </b-table-column>

            <b-table-column
              v-slot="pessoa"
              label="Ações"
              custom-key="actions"
              cell-class="is-actions-cell"
            >
              <div class="buttons is-right no-wrap">
                <b-tooltip
                  v-if="$util.hasAnyRole(['ROLE_ADMIN', 'ROLE_CADASTRO'])"
                  label="Aprovar"
                  type="is-light "
                >
                  <button
                    @click="atualizarStatus(pessoa.row, 'APROVADO')"
                    class="button is-small is-success"
                  >
                    <b-icon icon="thumb-up" size="is-small" />
                    &nbsp; Aprovar
                  </button>
                </b-tooltip>
                <b-tooltip
                  v-if="$util.hasAnyRole(['ROLE_ADMIN', 'ROLE_CADASTRO'])"
                  label="Recusar"
                  type="is-light "
                >
                  <button
                    @click="atualizarStatus(pessoa.row, 'RECUSADO')"
                    class="button is-small is-danger"
                  >
                    <b-icon icon="thumb-down" size="is-small" />
                    &nbsp; Recusar
                  </button>
                </b-tooltip>

                <b-tooltip
                  v-if="$util.hasAnyRole(['ROLE_ADMIN', 'ROLE_CADASTRO'])"
                  label="Cadastrar"
                  type="is-light "
                >
                  <button
                    @click="atualizarStatus(pessoa.row, 'CADASTRADO')"
                    class="button is-small is-info"
                  >
                    <b-icon icon="check" size="is-small" />
                    &nbsp; Cadastrar
                  </button>
                </b-tooltip>
                <b-tooltip label="Visualizar" type="is-light ">
                  <router-link
                    :to="{
                      name: 'FormPessoa.edit',
                      params: { id: pessoa.row.id },
                      query: { tipoPessoa: $route.query.tipoPessoa },
                    }"
                    class="button is-small is-info"
                  >
                    <b-icon icon="eye" size="is-small" />
                    &nbsp; Visualizar
                  </router-link>
                </b-tooltip>

                <b-tooltip
                  v-if="$route.query.tipoPessoa != 'VISITANTE'"
                  label="Solicitar Desligamento"
                  type="is-light "
                >
                  <b-button
                    @click.prevent="desligamentoOpen(pessoa.row)"
                    class="button is-small is-danger"
                  >
                    <b-icon size="is-small" />
                    &nbsp; Solicitar Desligamento
                  </b-button>
                </b-tooltip>

                <b-tooltip
                  v-if="$util.hasAnyRole(['ROLE_ADMIN', 'ROLE_CADASTRO'])"
                  label="Excluir"
                  type="is-light"
                >
                  <b-button
                    type="is-danger"
                    size="is-small"
                    @click.prevent="trashModalOpen(pessoa.row)"
                  >
                    <b-icon icon="trash-can" size="is-small" />
                  </b-button>
                </b-tooltip>
              </div>
            </b-table-column>

            <section slot="empty" class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="emoticon-sad" size="is-large" />
                </p>
                <p>Não há dados&hellip;</p>
              </div>
            </section>
          </b-table>
          <br />
          <section style="margin: 15px">
            <b-pagination
              :total="pageable.size"
              v-model="pageable.current"
              :range-before="3"
              :range-after="1"
              :order="''"
              :size="''"
              :simple="false"
              :rounded="false"
              :per-page="15"
              :icon-prev="'chevron-left'"
              :icon-next="'chevron-right'"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              :page-input="false"
              :page-input-position="''"
              :debounce-page-input="''"
            >
              <template #default="props">
                <b-pagination-button
                  :page="props.page"
                  :id="`page${props.page.number}`"
                  tag="router-link"
                  :to="`/pessoas?page=${
                    props.page.number
                  }&${$util.formatQueryUrl($route.query)}`"
                >
                  {{ props.page.number }}
                </b-pagination-button>
              </template>

              <template #previous="props">
                <b-pagination-button
                  :page="props.page"
                  tag="router-link"
                  :to="`/pessoas?page=${
                    props.page.number
                  }&${$util.formatQueryUrl($route.query)}`"
                >
                  Anterior
                </b-pagination-button>
              </template>

              <template #next="props">
                <b-pagination-button
                  :page="props.page"
                  tag="router-link"
                  :to="`/pessoas?page=${
                    props.page.number
                  }&${$util.formatQueryUrl($route.query)}`"
                >
                  Próximo
                </b-pagination-button>
              </template>
            </b-pagination>
            <template>
              <div><b>Total de Registros: </b> {{ totalElements }}</div>
            </template>
          </section>
        </card-component>
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import TitleBar from "@/components/TitleBar.vue";
import CardComponent from "@/components/CardComponent.vue";
import ModalDeletePessoa from "@/components/ModalDeletePessoa.vue";
import ModalDesligamento from "@/components/ModalDesligamento.vue";
import PessoaService from "@/services/PessoaService.js";
import storage from "@/mixins/storage";

export default defineComponent({
  name: "ListPessoa",
  components: {
    TitleBar,
    CardComponent,
    ModalDeletePessoa,
    ModalDesligamento,
  },
  data() {
    return {
      titleStack: ["SECAPI", "Pessoas", this.getTitle()],
      status: [
        {
          label: "APROVADO",
          value: "APROVADO",
        },
        {
          label: "CADASTRADO",
          value: "CADASTRADO",
        },
        {
          label: "DOCUMENTAÇÃO EXPIRADA",
          value: "DOCUMENTACAO_EXPIRADA",
        },
        {
          label: "EMPRESA INATIVA",
          value: "EMPRESA_INATIVA",
        },
        {
          label: "PENDENTE",
          value: "PENDENTE",
        },
        {
          label: "PENDENTE REMOÇÃO",
          value: "PENDENTE_REMOCAO",
        },
        {
          label: "RECUSADO",
          value: "RECUSADO",
        },
        {
          label: "Bloqueado",
          value: "BLOQUEADO",
        },
        {
          label: "DOCUMENTAÇÃO PENDENTE",
          value: "DOCUMENTACAO_PENDENTE",
        },
      ],
      filtro: {
        tipoPessoa: this.$route.query.tipoPessoa ?? null,
        empresa: null,
        nome: null,
        cpf: null,
        status: null,
      },
      pessoas: [],
      pageable: {
        current: this.$route.query.page,
        size: 0,
      },
      totalElements: null,
      totalPages: null,
      size: null,
      isModalActive: false,
      isModalDesligarPessoaActive: false,
      trashObject: null,
      desligarPessoaObject: null,
      checkedRows: [],
      statusSelecionado: null,
      listAtualizar: [
        { label: "Aprovar", value: "APROVADO", icon: "thumb-up" },
        { label: "Recusar", value: "RECUSADO", icon: "thumb-down" },
        { label: "Cadastro", value: "CADASTRADO", icon: "check" },
      ],
    };
  },
  computed: {
    pessoasTable() {
      return this.pessoas.map((pessoa) => {
        return {
          id: pessoa.id,
          nome: pessoa.nome,
          cpf: pessoa.cpf,
          empresa: pessoa.empresa.razaoSocial,
          status: pessoa.status,
          ativo: pessoa.ativo ? "ATIVO" : "INATIVO",
        };
      });
    },
  },
  mounted() {},
  methods: {
    buscar() {
      if (this.filtro) {
        this.pageable.current = 1;
        let queryFiltro = `pessoas?page=${
          this.pageable.current
        }${this.$util.formatQueryUrl(this.filtro)}&orderBy=nome,DESC`;
        this.$router.push(queryFiltro);
      }
    },
    desligamentoOpen(obj) {
      this.desligarPessoaObject = obj;
      this.isModalDesligarPessoaActive = true;
    },
    trashModalOpen(obj) {
      this.trashObject = obj;
      this.isModalActive = true;
    },
    trashConfirm() {
      this.isModalActive = false;
      const id = this.trashObject.id;

      PessoaService.deletePessoa(id)
        .then(() => {
          this.$buefy.toast.open({
            message: `Pessoa Deletada!`,
            position: "is-bottom",
            type: "is-info",
          });
          location.reload();
        })
        .catch((error) => {
          if (error.response) {
            this.$buefy.toast.open({
              message: `Erro ao deletar Pessoa: ${error.response.data.message}`,
              position: "is-bottom",
              type: "is-danger",
            });
          }
          console.error(error);
        });
    },
    trashCancel() {
      this.isModalActive = false;
    },
    desligarPessoaConfirm(pessoa) {
      PessoaService.desligarPessoa(pessoa.id, pessoa.dataDesligamento)
        .then(() => {
          this.$buefy.toast.open({
            message: `Pessoa Deletada!`,
            position: "is-bottom",
            type: "is-info",
          });
          location.reload();
        })
        .catch((error) => {
          if (error.response) {
            this.$buefy.toast.open({
              message: `Erro ao deletar Pessoa: ${error.response.data.message}`,
              position: "is-bottom",
              type: "is-danger",
            });
          }
          console.error(error);
        });
    },
    desligarPessoaCancel() {
      this.isModalDesligarPessoaActive = false;
      this.desligarPessoaObject = null;
    },
    estiloStatus(status) {
      switch (status) {
        case "APROVADO":
          return "is-success";
        case "CADASTRADO":
          return "is-info";
        case "RECUSADO":
          return "is-danger";
        case "PENDENTE":
          return "is-warning";
        case "PENDENTE_REMOCAO":
          return "is-warning";
        case "DOCUMENTACAO_EXPIRADA":
          return "is-warning";
        case "EMPRESA_INATIVA":
          return "is-warning";
        case "BLOQUEADO":
          return "is-danger";
      }
    },

    getTitle() {
      var name = this.$route.query.tipoPessoa;
      var title =
        name === "VISITANTE"
          ? "Visitante"
          : name === "MOTORISTA"
          ? "Motorista"
          : "Funcionário";
      return "Listar " + title;
    },

    permitirAlterarStatus() {
      const role = storage.methods.getRole();
      return role === "ROLE_CADASTRO" || role === "ROLE_ADMIN";
    },

    atualizarStatus(item, status) {
      this.$buefy.dialog.confirm({
        title: "Confirmar Alteração",
        message: `Deseja realmente alterar o status de <b>${item.nome}</b> para <b>${status}</b> ?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-info",
        hasIcon: true,
        onCancel: () => (this.statusSelecionado = null),
        onConfirm: () => this.atualizacaoStatusConfirmada(item.id, status),
      });
    },

    atualizacaoStatusConfirmada(id, status) {
      this.isLoading = true;
      PessoaService.putPessoaStatus(id, status)
        .then((result) => {
          this.$buefy.toast.open({
            message: "Status Atualizado com sucesso!",
            type: "is-success",
            position: "is-bottom",
          });
          location.reload();
        })
        .catch((error) => {
          this.$buefy.toast.open({
            message: "Não foi possível completar a operação!",
            type: "is-danger",
            position: "is-bottom",
          });
          console.error(error);
        })
        .finally(() => {
          this.statusSelecionado = null;
          setTimeout(() => (this.isLoading = false), 500);
        });
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.query) {
          this.pageable.current = parseInt(newVal.query.page);

          PessoaService.getPessoas(
            this.pageable.current - 1,
            this.$util.formatQueryUrl(this.$route.query)
          ).then((response) => {
            this.pessoas = response.data.content;
            this.pageable.current = response.data.number + 1;
            this.pageable.size = response.data.totalElements;
            this.totalPages = response.data.totalPages;
            this.totalElements = response.data.totalElements;
          });

          this.filtro = {};
          this.filtro.tipoPessoa = this.$route.query.tipoPessoa;
          this.getTitle();
          this.titleStack = ["SECAPI", "Pessoas", this.getTitle()];
        }
      },
    },
  },
});
</script>
