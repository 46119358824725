<template>
    <div>
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>
      <title-bar :title-stack="titleStack" />
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            <span class="icon"><i class="mdi mdi-ballot default"></i></span>
            {{ titleStack[1] }}
          </p>
          <router-link
            slot="right"
            :to="`/adicionarVeiculo?&${$util.formatQueryUrl($route.query)}`"
            class="button is-info"
            style="margin-right: 1.5rem; margin-top: 1rem; margin-bottom: 1rem"
          >
            Adicionar
          </router-link>
        </header>
  
        <div class="card-content">
          <form @submit.prevent="buscar()">
            <!-- Container responsivo -->
            <div class="columns is-multiline is-variable is-1-mobile">
              <!-- Empresa -->
              <div class="column is-full-mobile is-one-quarter-desktop">
                <div class="field">
                  <div class="control">
                    <label class="label">Empresa</label>
                    <b-input
                      v-model="filtro.empresa"
                      icon="card-bulleted-outline"
                      placeholder="Digite a Empresa"
                      name="empresa"
                      class="is-fullwidth"
                    />
                  </div>
                </div>
              </div>
  
              <!-- Placa -->
              <div class="column is-full-mobile is-one-quarter-desktop">
                <div class="field">
                  <div class="control">
                    <label class="label">Placa</label>
                    <b-input
                      v-model="filtro.placa"
                      icon="card-bulleted-outline"
                      placeholder="Digite a Placa"
                      name="placa"
                      class="is-fullwidth"
                    />
                  </div>
                </div>
              </div>
  
              <!-- Status -->
              <div class="column is-full-mobile is-one-quarter-desktop">
                <div class="field">
                  <div class="control">
                    <label class="label">Status</label>
                    <b-select
                      v-model="filtro.status"
                      placeholder="Status"
                      icon="card-bulleted-outline"
                      class="is-fullwidth"
                    >
                      <option
                        v-for="(item, index) in status"
                        :key="index"
                        :value="item.value"
                      >
                        {{ item.label }}
                      </option>
                    </b-select>
                  </div>
                </div>
              </div>
  
              <!-- Botão Buscar -->
              <div
                class="column is-full-mobile is-one-quarter-desktop is-flex is-align-self-flex-end"
              >
                <div class="field">
                  <div class="control">
                    <b-button
                      native-type="submit"
                      type="is-info"
                      class="is-fullwidth"
                    >
                      Buscar
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
  
        <section class="section is-main-section">
          <card-component class="has-table has-mobile-sort-spaced">
            <modal-delete-veiculo
              :is-active="isModalActive"
              :trash-object-name="trashObject ? trashObject.placa : null"
              @confirm="trashConfirm"
              @cancel="trashCancel"
            />
  
            <modal-desligamento
              :is-active="isModalDesligarVeiculoActive"
              :pessoa="desligarPessoaObject"
              @confirm="desligarVeiculoConfirm"
              @cancel="desligarVeiculoCancel"
            />
  
            <b-table
              :checked-rows.sync="checkedRows"
              :data="veiculosTable"
              default-sort="name"
              striped
              hoverable
            >
              <b-table-column
                v-slot="veiculo"
                label="Placa"
                field="placa"
                sortable
              >
                {{ veiculo.row.placa }}
              </b-table-column>
              <b-table-column
                v-slot="veiculo"
                label="Empresa"
                field="empresa"
                sortable
              >
                {{ veiculo.row.empresa }}
              </b-table-column>
              <b-table-column
                v-slot="veiculo"
                label="Marca"
                field="marca"
                sortable
              >
                {{ veiculo.row.marca }}
              </b-table-column>
              <b-table-column
                v-slot="veiculo"
                cell-class="is-progress-col"
                label="Modelo"
                field="modelo"
                sortable
              >
                {{ veiculo.row.modelo }}
              </b-table-column>
              <b-table-column v-slot="veiculo" label="Ano" field="ano" sortable>
                {{ veiculo.row.ano }}
              </b-table-column>
              <b-table-column
                v-slot="veiculo"
                label="Status"
                field="status"
                centered
                sortable
              >
                <span class="tag" :class="estiloStatus(veiculo.row.status)">
                  {{ veiculo.row.status }}
                </span>
              </b-table-column>
              <b-table-column
                v-slot="veiculo"
                label="Usuário Solicitação"
                field="usuario"
                sortable
              >
                {{ veiculo.row.usuario }}
              </b-table-column>
              <b-table-column
                v-slot="veiculo"
                label="Ações"
                custom-key="actions"
                cell-class="is-actions-cell"
                centered
              >
                <div class="buttons no-wrap is-centered">
                  <b-tooltip
                    v-if="$util.hasAnyRole(['ROLE_ADMIN', 'ROLE_CADASTRO'])"
                    label="Aprovar"
                    type="is-light "
                  >
                    <button
                      @click="atualizarStatus(veiculo.row, 'APROVADO')"
                      class="button is-small is-success"
                    >
                      <b-icon icon="thumb-up" size="is-small" />
                      &nbsp; Aprovar
                    </button>
                  </b-tooltip>
                  <b-tooltip
                    v-if="$util.hasAnyRole(['ROLE_ADMIN', 'ROLE_CADASTRO'])"
                    label="Recusar"
                    type="is-light "
                  >
                    <button
                      @click="atualizarStatus(veiculo.row, 'RECUSADO')"
                      class="button is-small is-danger"
                    >
                      <b-icon icon="thumb-down" size="is-small" />
                      &nbsp; Recusar
                    </button>
                  </b-tooltip>
  
                  <b-tooltip
                    v-if="$util.hasAnyRole(['ROLE_ADMIN', 'ROLE_CADASTRO'])"
                    label="Cadastrar"
                    type="is-light "
                  >
                    <button
                      @click="atualizarStatus(veiculo.row, 'CADASTRADO')"
                      class="button is-small is-info"
                    >
                      <b-icon icon="check" size="is-small" />
                      &nbsp; Cadastrar
                    </button>
                  </b-tooltip>
  
                  <b-tooltip label="Visualizar" type="is-light ">
                    <router-link
                      :to="{
                        name: 'FormVeiculo.edit',
                        params: { id: veiculo.row.id },
                        query: $route.query,
                      }"
                      class="button is-small is-info"
                    >
                      <b-icon icon="eye" size="is-small" />
                      &nbsp; Visualizar
                    </router-link>
                  </b-tooltip>
  
                  <b-tooltip
                    v-if="$route.query.tipoPessoa != 'VISITANTE'"
                    label="Solicitar Desligamento"
                    type="is-light "
                  >
                    <b-button
                      @click.prevent="desligamentoOpen(veiculo.row)"
                      class="button is-small is-danger"
                    >
                      <b-icon size="is-small" />
                      &nbsp; Solicitar Desligamento
                    </b-button>
                  </b-tooltip>
  
                  <b-tooltip
                    v-if="$util.hasAnyRole(['ROLE_ADMIN', 'ROLE_CADASTRO'])"
                    label="Excluir"
                    type="is-light"
                  >
                    <b-button
                      type="is-danger"
                      size="is-small"
                      @click.prevent="trashModalOpen(veiculo.row)"
                    >
                      <b-icon icon="trash-can" size="is-small" />
                    </b-button>
                  </b-tooltip>
                </div>
              </b-table-column>
  
              <section slot="empty" class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>Não há dados&hellip;</p>
                </div>
              </section>
            </b-table>
            <br />
            <section style="margin: 15px">
              <b-pagination
                :total="pageable.size"
                v-model="pageable.current"
                :range-before="3"
                :range-after="1"
                :order="''"
                :size="''"
                :simple="false"
                :rounded="false"
                :per-page="15"
                :icon-prev="'chevron-left'"
                :icon-next="'chevron-right'"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                :page-input="false"
                :page-input-position="''"
                :debounce-page-input="''"
              >
                <template #default="props">
                  <b-pagination-button
                    :page="props.page"
                    :id="`page${props.page.number}`"
                    tag="router-link"
                    :to="`/veiculos?page=${
                      props.page.number
                    }&${$util.formatQueryUrl($route.query)}`"
                  >
                    {{ props.page.number }}
                  </b-pagination-button>
                </template>
  
                <template #previous="props">
                  <b-pagination-button
                    :page="props.page"
                    tag="router-link"
                    :to="`/veiculos?page=${
                      props.page.number
                    }&${$util.formatQueryUrl($route.query)}`"
                  >
                    Anterior
                  </b-pagination-button>
                </template>
  
                <template #next="props">
                  <b-pagination-button
                    :page="props.page"
                    tag="router-link"
                    :to="`/veiculos?page=${
                      props.page.number
                    }&${$util.formatQueryUrl($route.query)}`"
                  >
                    Próximo
                  </b-pagination-button>
                </template>
              </b-pagination>
              <template>
                <div><b>Total de Registros: </b> {{ totalElements }}</div>
              </template>
            </section>
          </card-component>
        </section>
      </div>
    </div>
  </template>
  
  <style>
  .desativado.is-disabled {
    opacity: 1;
    cursor: initial;
  }
  </style>
  
  <script>
  import { defineComponent } from "vue";
  import TitleBar from "@/components/TitleBar.vue";
  import CardComponent from "@/components/CardComponent.vue";
  import ModalDeleteVeiculo from "@/components/ModalDeleteVeiculo.vue";
  import ModalDesligamento from "@/components/ModalDesligamento.vue";
  import VeiculoService from "@/services/VeiculoService.js";
  import storage from "@/mixins/storage";
  
  export default defineComponent({
    name: "ListVeiculo",
    components: {
      TitleBar,
      CardComponent,
      ModalDeleteVeiculo,
      ModalDesligamento,
    },
    data() {
      return {
        status: [
          {
            label: "APROVADO",
            value: "APROVADO",
          },
          {
            label: "CADASTRADO",
            value: "CADASTRADO",
          },
          {
            label: "PENDENTE",
            value: "PENDENTE",
          },
          {
            label: "PENDENTE REMOÇÃO",
            value: "PENDENTE_REMOCAO",
          },
          {
            label: "RECUSADO",
            value: "RECUSADO",
          },
          {
            label: "BLOQUEADO",
            value: "BLOQUEADO",
          },
        ],
        filtro: {
          empresa: null,
          placa: null,
          status: null,
          area: this.$route.query.area ?? null,
          classificacao: this.$route.query.classificacao ?? null,
          tipo: this.$route.query.tipo ?? null,
        },
        checkedRows: [],
        isModalActive: false,
        isModalDesligarVeiculoActive: false,
        trashObject: null,
        desligarPessoaObject: null,
        veiculos: [],
        pageable: {
          current: 1,
          size: 0,
        },
        totalElements: null,
        totalPages: null,
        listAtualizar: [
          { label: "Aprovar", value: "APROVADO", icon: "thumb-up" },
          { label: "Recusar", value: "RECUSADO", icon: "thumb-down" },
          { label: "Cadastro", value: "CADASTRADO", icon: "check" },
        ],
        statusSelecionado: null,
        isLoading: false,
      };
    },
    mounted() {},
    computed: {
      veiculosTable() {
        return this.veiculos.map((veiculo) => {
          return {
            id: veiculo.id,
            placa: veiculo.placa,
            empresa: veiculo.empresa.razaoSocial,
            marca: veiculo.marca,
            modelo: veiculo.modelo,
            ano: veiculo.anoFabricacao,
            status: veiculo.status,
            usuario: veiculo.usuario,
          };
        });
      },
      titleStack() {
        const data = [
          "SECAPI",
          this.$route.query.classificacao == "MOTOCICLETA"
            ? "Listar Motocicletas"
            : "Listar Veículos",
          this.$route.query.classificacao == "MOTOCICLETA"
            ? "Listar Motocicletas"
            : "Listar Veículos",
        ];
        return data;
      },
    },
    methods: {
      buscar() {
        if (this.filtro) {
          this.pageable.current = 1;
          let queryFiltro = `veiculos?page=${
            this.pageable.current
          }${this.$util.formatQueryUrl(this.filtro)}&orderBy=id,DESC`;
          this.$router.push(queryFiltro);
        }
      },
      trashModalOpen(obj) {
        this.trashObject = obj;
        this.isModalActive = true;
      },
      trashConfirm() {
        this.isModalActive = false;
        const id = this.trashObject.id;
  
        VeiculoService.deleteVeiculo(id)
          .then(() => {
            this.$buefy.toast.open({
              message: `Veículo Deletado!`,
              position: "is-bottom",
              type: "is-info",
            });
            location.reload();
          })
          .catch((error) => {
            this.$buefy.toast.open({
              message: `Houve algum Problema ao deletar Veículo`,
              position: "is-bottom",
              type: "is-danger",
            });
            console.error(error);
          });
      },
      trashCancel() {
        this.isModalActive = false;
      },
      desligamentoOpen(obj) {
        this.desligarPessoaObject = obj;
        this.isModalDesligarVeiculoActive = true;
      },
      desligarVeiculoConfirm(pessoa) {
        VeiculoService.desligar(pessoa.id, pessoa.dataDesligamento)
          .then(() => {
            this.$buefy.toast.open({
              message: `Pessoa Deletada!`,
              position: "is-bottom",
              type: "is-info",
            });
            location.reload();
          })
          .catch((error) => {
            if (error.response) {
              this.$buefy.toast.open({
                message: `Erro ao deletar Pessoa: ${error.response.data.message}`,
                position: "is-bottom",
                type: "is-danger",
              });
            }
            console.error(error);
          });
      },
      desligarVeiculoCancel() {
        this.isModalDesligarVeiculoActive = false;
        this.desligarPessoaObject = null;
      },
      estiloStatus(status) {
        switch (status) {
          case "APROVADO":
            return "is-success";
          case "CADASTRADO":
            return "is-info";
          case "RECUSADO":
            return "is-danger";
          case "PENDENTE":
            return "is-warning";
          case "PENDENTE_REMOCAO":
            return "is-warning";
          case "DOCUMENTACAO_EXPIRADA":
            return "is-warning";
          case "EMPRESA_INATIVA":
            return "is-warning";
          case "BLOQUEADO":
            return "is-danger";
        }
      },
  
      atualizarStatus(item, status) {
        this.$buefy.dialog.confirm({
          title: "Confirmar Alteração",
          message: `Deseja realmente alterar o status do veículo <b>${item.placa}</b> para <b>${status}</b> ?`,
          cancelText: "Cancelar",
          confirmText: "Confirmar",
          type: "is-info",
          hasIcon: true,
          onCancel: () => (this.statusSelecionado = null),
          onConfirm: () => this.atualizacaoStatusConfirmada(item.id, status),
        });
      },
  
      atualizacaoStatusConfirmada(idVeiculo, status) {
        this.isLoading = true;
        VeiculoService.putVeiculoStatus(idVeiculo, status)
          .then((result) => {
            this.$buefy.toast.open({
              message: "Status Atualizado com sucesso!",
              type: "is-success",
              position: "is-bottom",
            });
            location.reload();
          })
          .catch((error) => {
            this.$buefy.toast.open({
              message: "Não foi possível completar a operação!",
              type: "is-danger",
              position: "is-bottom",
            });
            console.error(error);
          })
          .finally(() => {
            this.statusSelecionado = null;
            setTimeout(() => (this.isLoading = false), 500);
          });
      },
  
      permitirAlterarStatus() {
        const role = storage.methods.getRole();
        return role === "ROLE_CADASTRO" || role === "ROLE_ADMIN";
      },
    },
    watch: {
      $route: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal.query) {
            this.pageable.current = parseInt(newVal.query.page);
  
            VeiculoService.getVeiculos(
              this.pageable.current - 1,
              this.$util.formatQueryUrl(this.$route.query)
            ).then((response) => {
              this.veiculos = response.data.content;
              this.pageable.current = response.data.number + 1;
              this.pageable.size = response.data.totalElements;
              this.totalPages = response.data.totalPages;
              this.totalElements = response.data.totalElements;
            });
  
            this.filtro = {};
            this.filtro.area = this.$route.query.area ?? null;
            this.filtro.classificacao = this.$route.query.classificacao ?? null;
            this.filtro.tipo = this.$route.query.tipo ?? null;
          }
        },
      },
    },
  });
  </script>
  